body {
  background-image: linear-gradient(
      rgba(147, 177, 60, 0.459),
      rgba(147, 177, 60, 0.041)
    ),
    url(./assets/bg.jpeg);
  background-position: center;
  background-size: contain;
  /* background-repeat: no-repeat; */
  height: 100vh;
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensure the container takes the full viewport height */
  overflow: hidden; /* Prevent scrolling */
}
.grid {
  width: 100vw;
  margin-left: 3vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 15vh;
}
.mole {
  width: 100px;
  height: 70px;
  float: none;
}
.hole {
  float: none;
  height: 70px;

  width: 100px;
}
.score {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px); /* Adjust the distance of upward movement */
    opacity: 0;
  }
}

.multiplier-animation {
  position: absolute;
  font-size: 24px;
  color: yellow;
  animation: moveUp 1s ease-in-out forwards;
}
